<template>
  <div class="code-original" v-if="!isProcessing">
    <qr-code :cid="cid" :redirectURL="redirectURL" />
  </div>
</template>

<script>
import { deeplink } from '@/deeplink.js'
import QrCode from '@/components/code/qr.vue'

export default {
  name: 'code-original',
  components: { QrCode },
  data () {
    return {
      // ディープリンク用のリダイレクトURL
      redirectURL: null
    }
  },
  async mounted () {
    if (!this.collection) {
      this.$router.push({ name: 'notfound' })
      return
    }

    // ディープリンクを設定（非対応ならnull）
    this.redirectURL = await deeplink.getDeeplink(this.collection)

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {String} コレクションのid
     */
    cid () {
      return this.$route.params.cid
    },
    /**
     * @return {Object} ひとつのQRコードに関する情報
     */
    collection () {
      const collection = this.$store.getters['collections/collection'](this.cid)
      return collection !== undefined ? collection : null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';

.code-original {
  top: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: calc(100vh - #{$header_height} - #{$footer_height});
  margin: $header_height 0 $footer_height;
  background-color: white;
}
</style>
