<template>
  <div class="qr">
    <h2 class="qr__name">{{ collectionName }}</h2>
    <h2 class="qr__category" v-for="category in categories" :key=category @click="moveListPage(category)">{{ category }}</h2>
    <qriously class="qr__img" :value="redirectURL ? redirectURL : url" :size=qrSize />
    <div class="qr__action">
      <div class="qr__action__cover">
        <v-btn class="qr__action__cover__btn" fab depressed @click="move()">
          <v-icon class="qr__action__cover__btn__icon">open_in_new</v-icon>
        </v-btn>
        開く
      </div>
      <div class="qr__action__cover">
        <v-btn class="qr__action__cover__btn" :disabled="isCopying" fab depressed @click="copyQR()">
          <v-icon class="qr__action__cover__btn__icon">share</v-icon>
        </v-btn>
        シェア
      </div>
      <div class="qr__action__cover">
        <v-btn class="qr__action__cover__btn" fab depressed @click="$router.push({ name: 'code_edit', params: { cid } })">
          <v-icon class="qr__action__cover__btn__icon">edit</v-icon>
        </v-btn>
        編集
      </div>
      <div class="qr__action__cover">
        <v-btn class="qr__action__cover__btn" fab depressed @click="$refs.deleting.open()">
          <v-icon class="qr__action__cover__btn__icon">delete</v-icon>
        </v-btn>
        削除
      </div>
      <deleting ref="deleting" msg="QRコードの削除" @delete="deleteQR()" />
    </div>
    <v-btn class="qr__btn" text @click="$router.push({ name: 'list' })" color="grey darken-1">
      <v-icon class="qr__btn__icon">reply</v-icon>
      一覧へ戻る
    </v-btn>
  </div>
</template>

<script>
import size from '@/assets/sass/size.scss'

import deleting from '@/components/common/confirm/deleting.vue'

export default {
  components: { deleting },
  props: {
    // コレクションのid
    cid: {
      type: String,
      required: true
    },
    // ディープリンク用のリダイレクトURL
    redirectURL: {
      type: String
    }
  },
  data () {
    return {
      // URLをコピー中か
      isCopying: false
    }
  },
  computed: {
    /**
     * @return {String} 現在選択中のカテゴリー
     */
    active () {
      return this.$store.getters['active']
    },
    /**
     * @return {Number} QRコードの横幅
     */
    qrSize () {
      const width = screen.width > size.max_width.replace('px', '') ? size.max_width.replace('px', '') : screen.width
      return width * 0.6
    },
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {Object} 表示したいQRコードのオブジェクト
     */
    collection () {
      return this.$store.getters['collections/collection'](this.cid)
    },
    /**
     * @return {String} コレクションの名前
     */
    collectionName () {
      return this.collection ? this.collection.collectionName : ''
    },
    /**
     * @return {String} コレクションのURL
     */
    url () {
      return this.collection ? this.collection.url : ''
    },
    /**
     * @return {Object[]} コレクションが持つカテゴリー名の一覧
     */
    categories () {
      return this.collection ? this.collection.categories : ''
    }
  },
  methods: {
    /**
     * ページ遷移
     */
    move () {
      window.location.href = this.redirectURL ? this.redirectURL : this.url
    },
    /**
     * QRコードをコピー
     * Web Share APIが使えない場合はクリップボードにコピー
     */
    copyQR () {
      this.isCopying = true
      if (navigator.share) {
        // Web Share APIが使用できる場合
        navigator.share({
          title: this.collectionName,
          url: this.url
        }).then(() => {
          this.isCopying = false
        }).catch(() => {
          this.isCopying = false
        })
      } else {
        this.$copyText(this.url).then(
          () => {
            // コピーしたらテロップを表示
            const msg = 'QRコードをコピーしました'
            this.$store.commit('setTelop', { show: true, msg: msg, type: 'success' })
            this.isCopying = false
          },
          () => {
            // コピーできなかったらエラーテロップを表示
            const msg = 'コピーできませんでした'
            this.$store.commit('setTelop', { show: true, msg: msg, type: 'error' })
            this.isCopying = false
          }
        )
      }
    },
    /**
     * QRコードを削除
     */
    async deleteQR () {
      this.$store.commit('setSubmitting', true)

      // 削除後にcidを使用するので変数に入れておく
      const deleteCID = this.cid

      const [link] = await Promise.all([
        this.$store.dispatch('links/getLinkContainsCID', { uid: this.uid, cid: deleteCID }),
        this.$store.dispatch('deeplinks/deleteDeeplink', { cid: deleteCID }),
        this.$store.dispatch('collections/deleteCollection', deleteCID)
      ])

      // リンク集への登録があればその情報も削除する
      if (link) {
        const params = {
          cids: link.cids.filter(cid => cid !== deleteCID),
          updatedAt: new Date()
        }
        await this.$store.dispatch('links/updateLink', { uid: this.uid, lid: link.lid, params: params })
      }

      // カテゴリーの再設定とナビゲーションの活性中のカテゴリーが存在するかの確認
      this.$store.commit('collections/resetCategory')
      this.$store.dispatch('resetActive')

      this.$router.push({ name: 'list' })
    },
    /**
     * 選択したカテゴリーをアクティブにしてlistへ遷移
     * listでは該当のカテゴリーが選択され、スクロールされた状態になる
     * @param {String} category カテゴリー名
     */
    moveListPage (category) {
      this.$store.commit('setActive', category)
      this.$router.push({ name: 'list' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';
@import '@/assets/sass/fontfamily.scss';

.qr {
  width: 100%;
  max-width: $max_width;
  padding: 0 $padding_width;
  margin: $header_height auto $footer_height auto;
  text-align: center;
  &__name {
    margin-top: 20px;
    font-size: 1.6rem;
    line-height: 2.3rem;
    text-align: center;
    word-break: break-all;
  }
  &__category {
    display: inline-block;
    padding: 5px 5px 0 5px;
    font-size: 1.2rem;
    font-weight: normal;
    line-height: 1.7rem;
    color: $gray_color;
  }
  &__img {
    margin-top: 20px;
  }
  &__action {
    width: 100%;
    padding: 0 $padding_width;
    margin-top: 20px;
    text-align: center;
    &__cover {
      display: inline-block;
      width: 15vw;
      min-width: 40px;
      max-width: 50px;
      margin: 0 15px;
      font-size: 1.2rem;
      line-height: 1.7rem;
      &__btn {
        width: 15vw;
        min-width: 40px;
        max-width: 50px;
        height: 15vw;
        min-height: 40px;
        max-height: 50px;
        margin-bottom: 5px;
        &.theme--light.v-btn:not(.v-btn--flat) {
          background-color: $light_gray_color;
        }
        &__icon {
          font-family: $material-outlined;
          &.v-icon {
            // レスポンシブに変わるようvwで指定
            // ボタンのサイズが15vwなので、それより小さい値を指定
            font-size: 9.5vw;
            color: $black_color;

            @media (min-width: 370px) {
              font-size: 2.5rem;
            }
          }
        }
        &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat) {
          background-color: $light_gray_color !important;
        }
        &.theme--light.v-btn.v-btn--disabled .v-icon {
          color: $black_color !important;
        }
      }
    }
  }
  &__btn {
    display: block;
    margin: 88px auto 20px;
    font-size: 1.2rem;
    line-height: 1.7rem;
    &__icon {
      &.v-icon {
        font-size: 2rem;
      }
    }
  }
}
</style>
