<template>
  <v-dialog class="deleting" v-model="dialog">
    <v-card class="deleting__area">
      <v-card-title class="deleting__area__msg">{{ msg }}</v-card-title>
      <v-card-text class="deleting__area__text">
        一度削除すると、復元することができなくなります。<br>
        本当に削除してもよろしいでしょうか？
      </v-card-text>
      <v-btn text class="deleting__area__btn" @click="doDelete()">はい</v-btn>
      <v-btn text class="deleting__area__btn" @click="dialog = false">いいえ</v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    msg: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    // ダイアログの表示
    open () {
      this.dialog = true
    },
    // デリート処理
    doDelete () {
      this.dialog = false
      this.$emit('delete')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.deleting {
  &__area {
    width: 100%;
    padding: 20px 20px 9px;
    text-align: center;
    background-color: white;
    border-radius: 15px;
    &__msg {
      display: block;
      margin: 0;
      &.v-card__title {
        padding: 0;
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 2rem;
        color: $black_color;
      }
    }
    &__text {
      margin: 15px 0 0;
      text-align: left;
      &.v-card__text {
        padding: 0;
        font-size: 1.4rem;
        line-height: 2rem;
        color: $black_color;
      }
    }
    &__btn {
      width: 40%;
      margin: 19px 5px 0;
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 2rem;
      color: $orange_color;
    }
  }
}
</style>

<style lang="scss">
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

// vuetify用
.v-dialog {
  width: 73%;
  max-width: 300px;
  background-color: white;
  border-radius: 15px;
  box-shadow: none;
}

.v-overlay--active {
  .v-overlay__scrim {
    background-color: $black_color !important;
    border: none;
    opacity: 0.7 !important;
  }
}
</style>
